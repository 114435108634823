<template>
  <div class="join center">
    <div class="baseBox">
      <div class="joinBox">
        <h2>아이디 찾기</h2>
        <div class="form">
          <div class="flex">
            <p>이름 <span>*</span></p>
            <input
              type="text"
              v-model="username"
              placeholder="이름을 입력해주세요."
            />
          </div>
          <div class="flex">
            <p>휴대폰번호 <span>*</span></p>
            <input
              type="text"
              v-model="mobileNo"
              @keyup="removeChar"
              placeholder="숫자만 입력해주세요."
            />
          </div>
        </div>
        <div v-if="error" class="error">
          해당 정보로 가입한 회원정보를 찾을 수 없습니다.
        </div>
        <div class="buttonWrap">
          <button class="point large" @click="submit">아이디찾기</button>
          <router-link to="/login">
            <button class="cancel large pointOut">취소</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import { findAccountID } from "@/api/index";
export default {
  mixins: [format],
  data() {
    return {
      mobileNo: "",
      username: "",
      error: false,
    };
  },

  methods: {
    removeChar(event) {
      if (
        event.keyCode == 8 ||
        event.keyCode == 46 ||
        event.keyCode == 37 ||
        event.keyCode == 39
      )
        return;
      else event.target.value = event.target.value.replace(/[^0-9]/g, "");
    },
    submit() {
      if (this.name == "") {
        return alert("이름을 입력해주세요.");
      } else if (this.phone == "") {
        return alert("핸드폰번호를 입력해주세요.");
      }
      this.error = false;
      let data = {
        username: this.username,
        mobileNo: this.mobileNo,
      };
      findAccountID(data).then((res) => {
        if (res.data.status == 200) {
          this.$router.push({
            name: "findEmailDone",
            query: { accountId: res.data.data },
          });
        } else {
          this.error = true;
        }
      });
    },
  },
};
</script>
